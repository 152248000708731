<template>
	<main 
		class="home-view full-container"
		@mousemove="methods.motionDetected"
		@keypress="methods.motionDetected"
		@scroll="methods.motionDetected"
		@click="methods.motionDetected"
	>
		<!-- BIG FORM FACTOR START ############################################################### -->

		<div :class="'big-form-factor background slide-' + state.slideNumber" />
		
		<div class="big-form-factor right-bar" />

		<div class="big-form-factor highlights-section">
			<div :class="'highlight-container shadow-3' + (state.slideNumber == 0 ? ' active' : '')">
				<div>
					<span>
						<span class="highlight-title">
							Welcome to Neobanx.
						</span>

						<span class="highlight-text">
							Where we provide a safe, convenient way to facilitate loans between friends and relatives.
						</span>
					</span>
				</div>
			</div>

			<div :class="'highlight-container shadow-3' + (state.slideNumber == 1 ? ' active' : '')">
				<div>
					<span>
						<span class="highlight-title">
							Help out your friends and relatives.
						</span>

						<span class="highlight-text">
							Our safe, secure environment makes it easy to lend money to people you know.
						</span>
					</span>
				</div>
			</div>

			<div :class="'highlight-container shadow-3' + (state.slideNumber == 2 ? ' active' : '')">
				<div>
					<span>
						<span class="highlight-title">
							Skip the banks.
						</span>

						<span class="highlight-text">
							Want to borrow from someone you know? Let us do the asking.
							We'll even handle all the details.
						</span>
					</span>
				</div>
			</div>
		</div>
		
		<div class="big-form-factor lower-content">
			<section class="controls-section">
				<!-- TODO: add controls here -->
			</section>

			<section class="details-section">
				<div class="details-container shadow-3">
					<a class="detail-title shadow-2" @click="state.slideNumber = 0">
						<q-icon name="circle" />
						How It Works
					</a>

					<div :class="'detail-text' + (state.slideNumber == 0 ? ' active' : '')">
						<div>
							<span>
								<b>Neobanx</b> enables you to connect with people you know for lending and borrowing money. 
								We make lending between Funding Direct safe, professional, and mutually beneficial.
							</span>

							<span>
								Our service handles the loan administration and legal documentation, automates payment transfers, 
								and even provides access to insurance to cover your payments if something goes wrong.
							</span>

							<a class="goto-url shadow-2">
								Learn more about Neobanx
								<q-icon name="arrow_forward" />
							</a>

							<div style="visibility: hidden; font-size: 24px;">X</div>
						</div>
					</div>

					<a class="detail-title shadow-2" @click="state.slideNumber = 1">
						<q-icon name="circle" />
						Lend To Friends And Family
					</a>

					<div :class="'detail-text' + (state.slideNumber == 1 ? ' active' : '')">
						<div>
							<span>
								Want to help out someone you know with a loan?
							</span>

							<span>
								Use Neobanx to send a loan proposal to whomever you'd like to help. Our loan tools make it easy
								for the two of you to negotiate terms, formalize the agreement and track repayments.
							</span>

							<a class="goto-url shadow-2">
								Learn more about lending
								<q-icon name="arrow_forward" />
							</a>

							<a class="goto-url-2 shadow-2">
								Start Lending Now
							</a>

							<div style="visibility: hidden; font-size: 24px;">X</div>
						</div>
					</div>

					<a class="detail-title shadow-2" @click="state.slideNumber = 2">
						<q-icon name="circle" />
						Borrow From Friends And Family
					</a>

					<div :class="'detail-text' + (state.slideNumber == 2 ? ' active' : '')">
						<div>
							<span>
								Need money to start a business, pay for school, fix a car or pay off a credit card?
							</span>

							<span>
								Use Neobanx to create your loan proposal and send it to a potential lender you know.
								Then you can negotiate until you both agree on terms.
							</span>

							<a class="goto-url shadow-2">
								Learn more about borrowing
								<q-icon name="arrow_forward" />
							</a>

							<a class="goto-url-2 shadow-2">
								Start Borrowing Now
							</a>

							<div style="visibility: hidden; font-size: 24px;">X</div>
						</div>
					</div>
				</div>
			</section>
		</div>

		<!-- SMALL FORM FACTOR START ############################################################# -->

		<div class="small-form-factor highlight-sff shadow-2">
			<div>
				<span>
					<span class="highlight-title">
						Welcome to Neobanx.
					</span>

					<span class="highlight-text">
						Where we provide a safe, convenient way to facilitate loans between friends and relatives.
					</span>
				</span>
			</div>
		</div>

		<div class="small-form-factor hero-image-sff image-0 shadow-2" />

		<div class="small-form-factor details-sff shadow-2">
			<div class="detail-title shadow-1">
				How It Works
			</div>
			
			<div class="detail-text">
				<span>
					<b>Neobanx</b> enables you to connect with people you know for lending and borrowing money. 
					We make lending between Funding Direct safe, professional, and mutually beneficial.
				</span>

				<span>
					Our service handles the loan administration and legal documentation, automates payment transfers, 
					and even provides access to insurance to cover your payments if something goes wrong.
				</span>

				<a class="goto-url shadow-2">
					Learn more about Neobanx
					<q-icon name="arrow_forward" />
				</a>
			</div>
		</div>

		<div class="small-form-factor divider-sff">&nbsp;</div>

		<div class="small-form-factor highlight-sff shadow-1">
			<div>
				<span>
					<span class="highlight-title">
						Help out your friends and relatives.
					</span>

					<span class="highlight-text">
						Our safe, secure environment makes it easy to lend money to people you know.
					</span>
				</span>
			</div>
		</div>

		<div class="small-form-factor hero-image-sff image-1 shadow-2" />

		<div class="small-form-factor details-sff shadow-2">
			<div class="detail-title shadow-1">
				Lend To Friends And Family
			</div>
			
			<div class="detail-text">
				<span>
					Want to help out someone you know with a loan?
				</span>

				<span>
					Use Neobanx to send a loan proposal to whomever you'd like to help. Our loan tools make it easy
					for the two of you to negotiate terms, formalize the agreement and track repayments.
				</span>

				<a class="goto-url shadow-2">
					Learn more about lending
					<q-icon name="arrow_forward" />
				</a>

				<a class="goto-url-2 shadow-2">
					Start Lending Now
				</a>
			</div>
		</div>

		<div class="small-form-factor divider-sff">&nbsp;</div>

		<div class="small-form-factor highlight-sff shadow-2">
			<div>
				<span>
					<span class="highlight-title">
						Skip the banks.
					</span>

					<span class="highlight-text">
						Want to borrow from someone you know? Let us do the asking.
						We'll even handle all the details.
					</span>
				</span>
			</div>
		</div>

		<div class="small-form-factor hero-image-sff image-2 shadow-2" />

		<div class="small-form-factor details-sff shadow-2">
			<div class="detail-title shadow-1">
				Borrow From Friends And Family
			</div>
			
			<div class="detail-text">
				<span>
					Need money to start a business, pay for school, fix a car or pay off a credit card?
				</span>

				<span>
					Use Neobanx to create your loan proposal and send it to a potential lender you know.
					Then you can negotiate until you both agree on terms.
				</span>

				<a class="goto-url shadow-2">
					Learn more about borrowing
					<q-icon name="arrow_forward" />
				</a>

				<a class="goto-url-2 shadow-2">
					Start Borrowing Now
				</a>
			</div>
		</div>
	</main>
</template>

<script>
import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
	name: 'HomeView',

	setup() {
		const state = reactive({
			slideNumber: 0,
			idleIntervalId: null
		});

		const methods = {
			startIdleInterval: () => {
				state.idleIntervalId = setInterval(() => {
					if (state.slideNumber == 2)
						state.slideNumber = 0
					else 
						state.slideNumber += 1
				}, 15000)
			},

			motionDetected: () => {
				clearInterval(state.idleIntervalId)
				methods.startIdleInterval()
			}
		}

		methods.startIdleInterval()

		// setTimeout(() => {
		// 	methods.startIdleInterval()	
		// }, 5000);

		return { state, methods }
	}
})
</script>

<style lang="scss" scoped>
.home-view {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	position: relative;
	//border: 1px solid #f00;

	// BIG FORM FACTOR START ###############################################################

	.big-form-factor {
		flex-shrink: 0;

		@media only screen and (max-width: 640px) {
			display: none !important;
		}
	}

	.background {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		width: calc(100% - 116px);
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		transition: background-image 1s;

		@media only screen and (max-width: 920px) {
			width: calc(100% - 58px);
		}

		@media only screen and (max-width: 720px) {
			width: 100%;
		}
	}

	.background.slide-0 {
		background-image: url(/images/backgrounds/family_001.jpg);
		background-position: right center;
	}

	.background.slide-1 {
		background-image: url(/images/backgrounds/friendship_001.jpg);
		background-position: 20% center;
	}

	.background.slide-2 {
		background-image: url(/images/backgrounds/hands_001.jpg);
		background-position: right center;
	}

	.right-bar {
		display: flex; 
		position: absolute; 
		width: 300px; 
		height: 100%; 
		right: 0; 
		top: 0; 
		background: linear-gradient(90deg, rgba(#fff, 0) 0, #30355C 60%, #30355C 100%); 
		z-index: 1;

		@media only screen and (max-width: 920px) {
			width: 150px;
		}

		@media only screen and (max-width: 720px) {
			display: none;
		}
	}

	.highlights-section {
		display: grid;
		padding: 0 16px;
		width: 100%;
		//border: 1px solid #f00;
		z-index: 1;

		.highlight-container {
			grid-row: 1;
			grid-column: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			backdrop-filter: blur(5px);
			background-color: rgba($color: #fff, $alpha: 0.85);
			border: 1px solid rgba($color: #fff, $alpha: 0.2);
			border-top: none;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			overflow: hidden;
			transform-origin: top center;
			transform: translateY(-100%);
			opacity: 0;
			transition: transform 0.5s ease-in, opacity 0.1s 0.5s ease-in;

			> div {
				display: flex;
				justify-content: center;
				align-self: center;
				text-align: center;
				width: 100%;
				padding: 16px 32px 16px 32px;
				overflow: hidden;

				> span {
					font-family: MartelBold;
					font-size: 24px;

					@media only screen and (max-width: 1520px) {
						font-size: 22px;
					}

					@media only screen and (max-width: 820px) {
						font-size: 18px;
					}

					// @media only screen and (max-width: 480px) {
					// 	font-size: 16px;
					// }

					.highlight-title {
						color: #EA7915;
					}

					.highlight-text {
						color: #5761A7;
					}
				}
			}
		}

		.highlight-container.active {
			transform: translateY(0);
			opacity: (1);
			transition: transform 0.5s 0.5s ease-out, opacity 0.1s 0.5s ease-out;
		}
	}

	.lower-content {
		display: flex;
		flex: 1;
		position: relative;
		overflow: auto;

		.controls-section {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			flex: 1;
			height: 100%;
			//border: 1px solid #00f;
			position: relative;
			z-index: 1;
		}

		.details-section {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			width: 100%;
			max-width: 400px;
			height: 95%;
			max-height: 600px;
			padding: 16px;
			//border: 1px solid #f00;
			z-index: 1;
			margin: auto;

			@media only screen and (max-width: 920px) {
				max-width: 360px;
			}

			.details-container {
				display: flex;
				width: 100%;
				height: 100%;
				flex-direction: column;
				border-radius: 8px;
				overflow: hidden;
				background: rgba($color: #d4c7ae, $alpha: 0.85);
				border: 1px solid rgba($color: #fff, $alpha: 0.2);
				backdrop-filter: blur(5px);

				> *:not(:last-child) {
					border-bottom: 1px solid #ddd;
				}

				.detail-title {
					display: flex;
					align-items: center;
					padding: 12px 16px;
					background: linear-gradient(90deg, #ea7915 0, #D16C13 100%);
					color: rgba($color: #fff, $alpha: 0.9);
					font-weight: bolder;
					text-transform: uppercase;
					cursor: pointer;

					// @media only screen and (max-width: 820px) {
					// 	font-size: 15px;
					// }

					.q-icon {
						font-size: 10px;
						margin-right: 8px;
						color: #EBB934;
					}
				}

				.detail-title:hover {
					color: #fff;
					filter: brightness(105%);
				}

				.detail-text {
					display: flex;
					flex: 0;
					min-height: 0;
					overflow: hidden;
					transition: all 0.5s;
					font-size: 15px;
					line-height: 22px;

					// @media only screen and (max-width: 820px) {
					// 	font-size: 15px;
					// }

					> div {
						display: flex;
						flex-direction: column;
						padding: 24px 16px 16px 16px;
						color: rgba($color: #000, $alpha: 0.65);

						> *:first-child {
							font-family: MartelBold;
							margin-top: 24px auto 0 auto;
						}

						> *:nth-child(2) {
							margin-top: 18px;
							background-color: transparent;
							padding-top: 0;
							padding-bottom: 0;
							font-family: ChivoRegular;
						}

						> span {
							display: block;
							padding: 16px;
							background-color: rgba(#fff, 0.25);
							border-radius: 4px;
						}

						.goto-url {
							display: flex;
							width: fit-content;
							align-items: center;
							padding: 2px 16px;
							border-radius: 4px;
							margin: 24px auto 0 auto;
							text-transform: uppercase;
							font-size: 12px;
							font-weight: bolder;
							color: rgba($color: #000, $alpha: 0.45);
							background-color: #EBB934;
							border: 1px solid rgba($color: #fff, $alpha: 0.2);
							cursor: pointer;

							.q-icon {
								font-size: 18px;
								font-weight: bolder;
								margin-left: 8px;
								color: #EA7915;
							}
						}

						.goto-url-2 {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 200px;
							height: 36px;
							min-height: 36px;
							color: rgba($color: #fff, $alpha: 0.7);
							font-size: 14px;
							cursor: pointer;
							margin: 32px auto 0 auto;
							border-radius: 8px; 
							background-color: #5761A7;
							border: 1px solid #ddd;
						}

						.goto-url:hover, .goto-url-2:hover {
							filter: brightness(105%);
						}
					}
				}

				.detail-text.active {
					flex: 1;
					overflow-y: auto;
				}
			}
		}
	}

	// SMALL FORM FACTOR START #############################################################

	.small-form-factor {
		flex-shrink: 0;

		@media only screen and (min-width: 640px) {
			display: none !important;
		}
	}

	.highlight-sff {
		display: flex;
		padding: 20px 16px;
		font-family: MartelBold;
		font-size: 20px;
		text-align: center;
		//border: 1px solid #f00;
		background-color: #efefef;
		z-index: 3;

		@media only screen and (max-width: 480px) {
			font-size: 18px;
		}

		.highlight-title {
			color: #EA7915;
		}

		.highlight-text {
			color: #5761A7;
		}
	}

	.hero-image-sff {
		display: flex;
		width: 100%;
		height: 40vh;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		//border: 1px solid #f00;
		z-index: 2;
	}

	.hero-image-sff.image-0 {
		background-image: url(/images/backgrounds/family_001.jpg);
	}

	.hero-image-sff.image-1 {
		background-image: url(/images/backgrounds/friendship_001.jpg);
	}

	.hero-image-sff.image-2 {
		background-image: url(/images/backgrounds/hands_001.jpg);
	}

	.details-sff {
		display: flex;
		flex-direction: column;
		z-index: 1;

		.detail-title {
			display: flex;
			padding: 12px 16px;
			background: linear-gradient(90deg, #ea7915 0, #D16C13 100%);
			border-bottom: 1px solid rgba(#fff, 0.1);
			color: rgba($color: #fff, $alpha: 0.9);
			font-weight: bolder;
			text-transform: uppercase;
			z-index: 1;
		}

		.detail-text {
			display: flex;
			flex-direction: column;
			padding: 24px 16px 24px 16px;
			height: auto;
			color: rgba($color: #000, $alpha: 0.65);
			background: rgba($color: #d4c7ae, $alpha: 0.85);
			font-size: 15px;

			> *:first-child {
				font-family: MartelBold;
				margin-top: 24px auto 0 auto;
			}

			> *:nth-child(2) {
				margin-top: 18px;
				background-color: transparent;
				padding-top: 0;
				padding-bottom: 0;
				font-family: ChivoRegular;
			}

			> span {
				display: block;
				padding: 16px;
				background-color: rgba(#fff, 0.25);
				border-radius: 4px;
			}

			.goto-url {
				display: flex;
				width: fit-content;
				align-items: center;
				padding: 2px 16px;
				border-radius: 4px;
				margin: 24px auto 0 auto;
				text-transform: uppercase;
				font-size: 12px;
				font-weight: bolder;
				color: rgba($color: #000, $alpha: 0.45);
				background-color: #EBB934;
				border: 1px solid rgba($color: #fff, $alpha: 0.2);
				cursor: pointer;

				.q-icon {
					font-size: 18px;
					font-weight: bolder;
					margin-left: 8px;
					color: #EA7915;
				}
			}

			.goto-url-2 {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 200px;
				height: 36px;
				min-height: 36px;
				color: rgba($color: #fff, $alpha: 0.7);
				font-size: 14px;
				cursor: pointer;
				margin: 32px auto 0 auto;
				border-radius: 8px; 
				background-color: #5761A7;
				border: 1px solid #ddd;
			}

			.goto-url:hover, .goto-url-2:hover {
				filter: brightness(105%);
			}
		}
	}

	.divider-sff {
		display: flex;
		width: 100%;
		height: 12px;
		background-color: #5761A7;
	}
}
</style>